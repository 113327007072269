// Libs
import React from 'react';
// Deps
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import styles from '../components/donation/donation-form.module.scss';
import DonationForm from '../components/donation/donation-form';

const DonationRequest = () => {
  return (
    <Layout>
      <div className={styles.donationFormContainerColored}>
        <SEO title="STORE DONATION REQUEST" />
        <DonationForm isStore={true} />
      </div>
    </Layout>
  );
};

export default DonationRequest;
